<template>
  <div class="page-view relative">
    <div class="text-block sticky top-0">
      <edit-button-badge
        :allow-edit="allowEdit"
        :post-status="postStatus"
        class="text-block absolute top-0 right-0"
        @open-editor="openEditor" />
      <h1 class=" text-3xl mt-sm capitalize">{{title}}</h1>
    </div>
    <div v-for="item in attachments" :key="item.id" class="attachment mt-base mb-sm">
      <div class="attachment-box relative">
        <img
          :src="item.url"
          :alt="item.caption"
          :width="(item.dimensions || {}).w"
          :height="(item.dimensions || {}).h"
          class="bg-gray-400"/>
        <div v-if="item.caption" class="text-block text-sm">
          {{item.caption}}
        </div>
      </div>
    </div>
    <div class="text-block mt-base" v-html="content" />
    <template v-if="template">
      <component :is="template" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { db } from '@/lib/firebase'
import PostData from '../mixins/post-data'
import EditButtonBadge from './components/EditButtonBadge'
import About from './components/page-templates/About'

export default {
  name: 'PageView',
  components: { EditButtonBadge, About },
  mixins: [PostData],
  props: {},

  data: () => ({
    postData: {},
    collectionRef: db.collection('pages')
  }),

  computed: {
    ...mapState(['user']),
    allowEdit () { return !!this.user && (this.user.role === 'admin' || this.user.role === 'editor') },
    postId () {
      return (this.$route.name === 'cv') ? 'cv' : this.$route.params.id
    },
    template () {
      const t = this.postData.template
      const components = ['Residency', 'About', 'Partners']
      return components.includes(t) ? t : null
    }
  },
  watch: {
    $route () {
      if ((this.$route.name !== 'page' && this.$route.name !== 'cv') ||
        this.postData.id === this.$route.params.id) {
        // if rooter is changed by popup ... getting in or out of the popup
        return
      }
      this.unsubscribePost()
      this.subscribeToPost()
    }
  },

  methods: {
    ...mapActions(['showEditor']),
    openEditor () {
      if (this.postData) {
        this.showEditor({
          value: this.postData,
          type: this.postData.id === 'cv' ? 'cv' : 'page'
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .page-view {
    h1{
      font-family: var(--font-main);
    }
  }
</style>
