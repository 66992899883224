<template>
  <div>
    <post-feed
      class="post-feed-tag flex-grow"
      :posts="posts"
      :processing="processing"
    >
      <!-- <template v-slot:post-cell="{ post, cellOffsetStyle }">
        <div class="relative" :style="cellOffsetStyle">{{post.title}}</div>
      </template> -->
    </post-feed>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { db } from '@/lib/firebase'
import FeedSubscription from '../mixins/feed-subscription'
import PostFeed from './components/PostFeed.vue'

export default {
  name: 'PostFeedByTag',
  mixins: [FeedSubscription],
  components: { PostFeed },

  data: () => ({
    feed: {},
    includeDrafts: false,
    orderBy: { field: 'year', direction: 'desc' }
  }),
  computed: {
    ...mapState(['curTag']),
    posts () {
      const uniqueTitles = []
      let posts = []
      if (this.tagId) {
        posts = Object.values(this.feed).sort((a, b) => b.year - a.year)
      } else {
        posts = Object.values(this.feed)
          .filter(post => !(post.tagIds || []).length)
          .sort((a, b) => b.year - a.year)
      }
      return [{ title: '←', type: 'back-button' }, ...posts]
        .map(item => {
          const firstL = item.title[0]
          let shortTitle = item.title.substr(0, 1)
          if (uniqueTitles.indexOf(firstL) < 0) {
            uniqueTitles.push(firstL)
          } else {
            shortTitle = item.title.substr(0, 2)
          }
          return { ...item, shortTitle }
        })
    },
    tagId () {
      return (this.$route.params.id || '').trim()
    },
    tagTitle () {
      return (this.curTag || {}).title || ''
    }
  },
  created () {
    this.setTag()
  },
  watch: {
    processing (val) {
      if (val) return
      setTimeout(() => {
        if (!this.$refs['logo-box']) return
        const top = this.$refs['logo-box'].getBoundingClientRect().bottom
        window.scrollTo({ top, behavior: 'smooth' })
      }, 500)
    },
    $route () {
      this.setTag()
    }
  },

  methods: {
    ...mapActions(['updateCurTag']),
    async setTag () {
      if ((this.curTag || {}).id === this.tagId) return
      if (!this.tagId) {
        if (this.curTag) {
          this.updateCurTag(null)
        }
        return
      }
      try {
        const tagSnap = await db.collection('tags').doc(this.tagId).get()
        if (tagSnap.exists) {
          this.updateCurTag({ ...tagSnap.data(), id: tagSnap.id })
        }
      } catch (e) {
        this.updateCurTag(null)
        console.error(e)
      }
    }
  }

}
</script>

<style lang='scss'>
</style>
